import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TooltipArrow } from "src/features/common/Icons";
import { stepClass } from "./WorkflowTaskStep";
import { formatDateFullYear } from "src/features/common/helper";
import { useIntl } from "react-intl";
import translate from "src/i18n/translate";
import { WmButton } from "@watermarkinsights/ripple-react";
import useSmallScreenView from "src/features/common/useSmallScreenView";
export function WorkflowTaskStepTooltip(_a) {
    var tooltipData = _a.tooltipData, tooltipRef = _a.tooltipRef, closeTooltip = _a.closeTooltip;
    var isSmallScreen = useSmallScreenView(1124);
    var stepIndex = useIntl().formatMessage({ id: "app.home.reviewsInProgress.stepIndex" }, {
        stepIndex: tooltipData && (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.stepIndex) + 1,
        totalSteps: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.totalSteps
    });
    function closeWithTab(event) {
        if (event.key === "Tab") {
            if (closeTooltip) {
                closeTooltip();
            }
        }
    }
    function getTaskState(state) {
        switch (state) {
            case "SUBMITTED":
                return useIntl().formatMessage({
                    id: "app.home.reviewsInProgress.completedState"
                });
            case "IN_PROGRESS":
                return useIntl().formatMessage({
                    id: "app.home.reviewsInProgress.inProgressState"
                });
            case "NOT_STARTED":
                return useIntl().formatMessage({
                    id: "app.home.reviewsInProgress.notStartedState"
                });
        }
    }
    function handleTooltipClose() {
        if (closeTooltip) {
            var stepElement = document.querySelector('.step[tabIndex="0"]');
            if (stepElement) {
                stepElement.tabIndex = -1;
            }
            closeTooltip();
        }
    }
    return (tooltipData && (_jsxs(_Fragment, { children: [_jsx("div", { className: "tooltip-pointer", style: { left: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.carrotPosition) + "px" }, children: _jsx(TooltipArrow, {}) }), _jsxs("div", { style: isSmallScreen
                    ? { marginLeft: "0" }
                    : { marginLeft: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.positionX) + "px" }, className: isSmallScreen ? "tooltip-small-screen" : "task-step-tooltip", ref: tooltipRef, children: [!isSmallScreen && (_jsx(_Fragment, { children: _jsx("div", { className: "close-button", children: _jsx(WmButton, { "button-type": "navigational", "tooltip-position": "left", class: "-navigational hydrated", icon: "f156", tooltip: "Close", onClick: handleTooltipClose, onKeyDown: function (event) { return closeWithTab(event); } }) }) })), _jsxs("div", { className: "tooltip-header", children: [_jsx("div", { className: "tooltip-state ".concat(stepClass(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.state), " "), children: getTaskState(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.state) }), _jsx("div", { className: "step-index", children: "(".concat(stepIndex, ")") })] }), !isSmallScreen && (_jsx("div", { className: "step-name", children: tooltipData.name })), _jsx(TooltipState, { tooltipData: tooltipData }), _jsxs("div", { className: "assignee", children: [_jsxs("span", { children: [translate("app.home.reviewsInProgress.assignedTo"), " "] }), tooltipData.assignees.map(function (assignee, index) {
                                return (_jsxs("div", { children: [_jsxs("span", { children: [" ", "".concat(assignee.firstName)] }), _jsxs("span", { children: [" ", assignee.middleName ? assignee.middleName : ""] }), _jsxs("span", { children: [" ", assignee.lastName] }), index < tooltipData.assignees.length - 1 ? "," : ""] }, "".concat(tooltipData.id, " ").concat(index)));
                            })] })] })] })));
}
function TooltipState(_a) {
    var tooltipData = _a.tooltipData;
    var completed = useIntl().formatMessage({ id: "app.home.reviewsInProgress.completed" }, {
        date: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.submittedDate) &&
            formatDateFullYear(tooltipData.submittedDate)
    });
    var dueDays = useIntl().formatMessage({ id: "app.home.reviewsInProgress.dueDays" }, {
        daysNumber: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.daysUntilDue
    });
    var dueDate = useIntl().formatMessage({ id: "app.home.taskDueDate" }, {
        date: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.dueDate) && formatDateFullYear(tooltipData.dueDate)
    });
    return (tooltipData && (_jsx(_Fragment, { children: tooltipData.submittedDate ? (_jsx("div", { className: "date", children: completed })) : (_jsx(_Fragment, { children: tooltipData.dueDateType === "FIXED" ? (_jsx("div", { className: "date", children: dueDate })) : (_jsx("div", { className: "date", children: dueDays })) })) })));
}
