import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockIcon, TickIcon } from "src/features/common/Icons";
export function stepClass(state) {
    switch (state) {
        case "IN_PROGRESS":
            return "step-in-progress";
        case "NOT_STARTED":
            return "step-not-started";
        case "SUBMITTED":
            return "step-submitted";
        default:
            return "";
    }
}
export function WorkflowTaskStep(_a) {
    var step = _a.step, allExpanded = _a.allExpanded, openTooltip = _a.openTooltip, clickedStep = _a.clickedStep, expandStepOnClick = _a.expandStepOnClick;
    var showFullNameOnClick = clickedStep === step.stepIndex;
    function stepClassTooltip() {
        return showFullNameOnClick ? "full-width" : "";
    }
    function showInProgressStepName() {
        return showFullNameOnClick || clickedStep === -1;
    }
    function expandedWithName() {
        return showInProgressStepName() && step.state === "IN_PROGRESS"
            ? "show-expanded"
            : "";
    }
    function handleClickStep(event, stepIndex) {
        var stepElement = document.querySelector('.step[tabIndex="0"]');
        if (stepElement) {
            stepElement.tabIndex = -1;
        }
        expandStepOnClick(stepIndex);
        openTooltip(event, stepIndex);
    }
    return (_jsx("div", { className: allExpanded
            ? "step tabbing ".concat(stepClass(step.state), " hover")
            : "step tabbing ".concat(stepClass(step.state), " ").concat(stepClassTooltip(), " ").concat(expandedWithName(), "  hover"), onClick: function (event) {
            handleClickStep(event, step.stepIndex);
        }, children: allExpanded ? (_jsxs("div", { className: "flex-row in-progress-content", children: [step.state !== "NOT_STARTED" && (_jsxs("div", { className: "clock-icon", children: [step.state === "IN_PROGRESS" && _jsx(ClockIcon, {}), step.state === "SUBMITTED" && _jsx(TickIcon, {})] })), _jsx("div", { className: "step-name step-name-expanded", children: step.name })] })) : (_jsxs("div", { className: "step-content", children: [step.state === "IN_PROGRESS" && (_jsxs("div", { className: "flex-row in-progress-content", children: [_jsx("div", { className: "clock-icon", children: _jsx(ClockIcon, {}) }), showInProgressStepName() && (_jsx("div", { className: "step-name", children: step.name }))] })), step.state === "SUBMITTED" && (_jsxs("div", { className: "flex-row in-progress-content", children: [_jsx("div", { className: "clock-icon", children: _jsx(TickIcon, {}) }), showFullNameOnClick && (_jsx("div", { className: "step-name", children: step.name }))] })), step.state === "NOT_STARTED" &&
                    (showFullNameOnClick ? (_jsx("div", { className: "step-name", children: step.name })) : (step.stepIndex + 1))] })) }));
}
