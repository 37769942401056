import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import translate from "src/i18n/translate";
import "./Header.scss";
import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
var REDIRECT_URI = runConfig.REDIRECT_URI;
function getOrganizationType(orgType) {
    return orgType ? orgType[0].toUpperCase() + orgType.slice(1) : "Institution";
}
function getPlanningUrl(organization, institution) {
    var orgUuid = (organization && organization.uuid) || (institution === null || institution === void 0 ? void 0 : institution.rootOrgUuid);
    return orgUuid
        ? "".concat(REDIRECT_URI, "/institution/").concat(institution === null || institution === void 0 ? void 0 : institution.pssUuid, "/org_identity/").concat(orgUuid)
        : "".concat(REDIRECT_URI);
}
function getInsightsUrl(organization, institution) {
    return institution && organization
        ? "/insights/planning/institution/".concat(institution.platformUuid, "/organization/").concat(organization.uuid)
        : "/";
}
export default function PssSideNav(_a) {
    var organization = _a.organization, pssOrganization = _a.pssOrganization;
    var institution = useContext(AppContext).institution;
    var planningURI = pssOrganization || institution
        ? getPlanningUrl(pssOrganization, institution)
        : "";
    var organizationType = (organization === null || organization === void 0 ? void 0 : organization.type) || pssOrganization.type;
    var sideNavBody = function () {
        var orgType = getOrganizationType(pssOrganization.type);
        var insightsUrl = getInsightsUrl(organization, institution);
        return (_jsx("div", { id: "new-side-nav", className: "org-side-nav", children: _jsx("div", { className: "nav-main", children: _jsx("nav", { id: "sidemainnav", className: "main-nav-org side-navs item-9", children: _jsxs("ul", { id: "sidenavmenu", className: "menu", children: [_jsx("li", { className: "menuitem active-parent", children: _jsx("a", { href: insightsUrl, id: "insights-nav", tabIndex: 0, className: "navlinks active", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-chart-bar" }), _jsx("div", { className: "menuname -name ", children: "Insights" })] }) }) }), _jsx("li", { className: "menuitem", children: _jsx("a", { href: planningURI + "?tab=inProgress", id: "in-progress-nav", tabIndex: 0, className: "navlinks", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-folder-edit-outline ", "aria-hidden": true }), _jsx("div", { className: "menuname -name ", children: translate("app.sidenav.inProgress") })] }) }) }), _jsx("li", { className: "menuitem", children: _jsx("a", { href: planningURI + "?tab=outcomes", id: "insitution-info-nav", tabIndex: 0, className: "navlinks", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-information-outline" }), _jsx("div", { className: "menuname -name ", children: translate("app.sidenav.orgTypeInformation", {
                                                    orgType: orgType
                                                }) })] }) }) }), institution &&
                                renderCurriculumMenu(institution.showCurriculumTab, planningURI, organizationType), _jsx("li", { className: "menuitem", children: _jsx("a", { href: planningURI + "?tab=actions", id: "actions-nav", tabIndex: 0, className: "navlinks", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-checkbox-marked-circle-outline" }), _jsx("div", { className: "menuname -name ", children: translate("app.sidenav.actions") })] }) }) }), _jsx("li", { className: "menuitem", children: _jsx("a", { href: planningURI + "?tab=docs", id: "docs-reports-nav", tabIndex: 0, className: "navlinks", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-file-document-box-multiple-outline", "aria-hidden": true }), _jsx("div", { className: "menuname -name ", children: translate("app.sidenav.docs&Reports") })] }) }) })] }) }) }) }));
    };
    var renderCurriculumMenu = function (showCurriculumMenu, planningURI, organizationType) {
        if (showCurriculumMenu || organizationType === "program") {
            return (_jsx("li", { className: "menuitem", children: _jsx("a", { href: planningURI + "?tab=curriculum", id: "curriculum-nav", tabIndex: 0, className: "navlinks", children: _jsxs("div", { className: "flex-row -verticallycentered -option", children: [_jsx("div", { className: "-icons mdi mdi-book-outline", "aria-hidden": true }), _jsx("div", { className: "menuname -name ", children: translate("app.sidenav.curriculum") })] }) }) }));
        }
    };
    return _jsx(_Fragment, { children: sideNavBody() });
}
