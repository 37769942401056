var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useReducer } from "react";
import { applyPolyfills, defineCustomElements } from "@watermarkinsights/ripple/dist/loader";
import { IntlProvider } from "react-intl";
import ENGLISH from "../../../i18n/languages/en-US.json";
import { useParams } from "react-router-dom";
import { pig } from "../../../static/js/pig.js";
import Header from "./Header";
import Loader from "../../common/elements/Loader";
import { sendRequest } from "src/common/http";
import ParticipationOverview from "../ParticipationOverview";
import "../Sll.scss";
import "../../../app/App.scss";
import AssessmentProgressChart from "./AssessmentProgressChart";
import { defaultReportingYear } from "../../common/interfaces/Interface";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
var sllAction = {
    type: "",
    reportingYear: defaultReportingYear,
    locale: "en-US",
    learningAssessments: {
        noActivityTerms: "",
        participationOverview: null,
        studentAssessmentProgressByTerm: []
    }
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setReportingYear":
            if (action.reportingYear) {
                return __assign(__assign({}, state), { reportingYear: action.reportingYear });
            }
            else {
                return __assign(__assign({}, state), { reportingYear: null, reportingYearAuth: false });
            }
        case "setLocale":
            return setLocale(state, action.locale);
        case "setLearningAssessments":
            return __assign(__assign({}, state), { learningAssessments: action.learningAssessments });
        default:
            return state;
    }
};
var setLocale = function (state, locale) {
    switch (locale) {
        case "pig": {
            return __assign(__assign({}, state), { locale: "en-US", messages: pig(ENGLISH) });
        }
        default: {
            return __assign(__assign({}, state), { locale: "en-US", messages: ENGLISH });
        }
    }
};
export default function SllExportView(_a) {
    var _b;
    var auth = _a.auth;
    applyPolyfills().then(function () {
        defineCustomElements();
    });
    var initialState = {
        reportingYearAuth: true,
        locale: "en-US",
        messages: null,
        reportingYear: null,
        learningAssessments: {
            noActivityTerms: "",
            participationOverview: null,
            studentAssessmentProgressByTerm: []
        }
    };
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    var reportingYearUuid = useParams().reporting_year_uuid;
    useEffect(function () {
        var queryString = window.location.search;
        var param = new URLSearchParams(queryString);
        var locale = param.get("locale");
        dispatch(__assign(__assign({}, sllAction), { type: "setLocale", locale: locale }));
    }, []);
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, sllAction), { type: "setReportingYear", reportingYear: result }));
        };
        auth &&
            sendRequest({
                uri: "/sa/reporting_years/".concat(reportingYearUuid),
                camelize: true
            }, applyResult);
    }, [auth]);
    useEffect(function () {
        if (state.reportingYear) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, sllAction), { type: "setLearningAssessments", learningAssessments: result }));
            };
            auth &&
                sendRequest({
                    uri: "/sll/learning_assessments?start_date=".concat(state.reportingYear.startDate, "&end_date=").concat(state.reportingYear.endDate, "&with_activities=").concat(true),
                    camelize: true
                }, applyResult);
        }
    }, [auth, state.reportingYear]);
    return (_jsx(_Fragment, { children: auth && state.reportingYearAuth ? (_jsx("div", { className: "pagewrapper sll-html-container", children: _jsx("div", { className: "insights-hub-body flex-row", children: _jsx("div", { className: "nav-main", children: _jsx("div", { className: "contentwrapper", children: _jsx("main", { id: "maincontent", className: "main-container", children: state.messages && (_jsxs(IntlProvider, { locale: state.locale, messages: state.messages, children: [!state.reportingYear ||
                                        !((_b = state.learningAssessments) === null || _b === void 0 ? void 0 : _b.participationOverview) ? (_jsx(Loader, {})) : (""), _jsxs("div", { id: "sll-html", className: "sll-html-container", children: [state.reportingYear && (_jsx(Header, { reportingYear: state.reportingYear.name })), _jsx("section", { className: "-mainheader -withbreadcrumbs only-breadcrumbs body-padding", id: "page-header", children: _jsxs("div", { id: "participation-overview", className: "sll-section sll-section-html", children: [state.reportingYear && (_jsx(ParticipationOverview, { overview: state.learningAssessments
                                                                .participationOverview, reportingYear: state.reportingYear.name })), state.reportingYear && (_jsx(AssessmentProgressChart, { assessmentProgress: state.learningAssessments
                                                                .studentAssessmentProgressByTerm, noActivityTerms: state.learningAssessments.noActivityTerms }))] }) })] })] })) }) }) }) }) })) : (_jsx(UnauthorizedComponent, {})) }));
}
