var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useContext, useEffect, useReducer } from "react";
import { sendRequest } from "src/common/http";
import { AppContext } from "src/contexts/AppContext";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
import translate from "src/i18n/translate";
import "../../sll/Sll.scss";
import { ParticipationOverview } from "./ParticipationOverview";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "src/features/common/helper";
var stateReducer = function (state, action) {
    switch (action.type) {
        case "showLoader":
            return __assign(__assign({}, state), { loader: true });
        case "setLearningAssessments":
            return __assign(__assign({}, state), { learningAssessments: action.learningAssessments, loader: false });
        case "setCurrentReportingYear":
            return __assign(__assign({}, state), { reportingYear: action.reportingYear });
        default:
            return state;
    }
};
var defaultAction = {
    type: "",
    learningAssessments: {
        noActivityTerms: "",
        participationOverview: null,
        studentAssessmentProgressByTerm: []
    },
    reportingYear: null
};
export function ProgramInsights(_a) {
    var currentReportingYear = _a.currentReportingYear;
    var _b = useContext(AppContext), institution = _b.institution, sysUser = _b.sysUser;
    var initialState = {
        loader: true,
        learningAssessments: {
            noActivityTerms: "",
            participationOverview: null,
            studentAssessmentProgressByTerm: []
        },
        reportingYear: null
    };
    var _c = useReducer(stateReducer, initialState), state = _c[0], dispatch = _c[1];
    var navigate = useNavigate();
    useEffect(function () {
        if (sysUser && sysUser.roles.includes("administrator")) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setCurrentReportingYear", reportingYear: currentReportingYear }));
        }
    }, [currentReportingYear]);
    useEffect(function () {
        if (institution && institution.platformUuid && state.reportingYear) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setLearningAssessments", learningAssessments: result }));
            };
            dispatch(__assign(__assign({}, defaultAction), { type: "showLoader" }));
            sendRequest({
                uri: "/sll/learning_assessments?start_date=".concat(state.reportingYear.startDate, "&end_date=").concat(state.reportingYear.endDate, "&with_activities=").concat(false),
                camelize: true
            }, applyResult);
        }
    }, [institution && institution.platformUuid, state.reportingYear]);
    function showParticipatioOverview(reportingYear, learningAssessments) {
        var _a;
        return (reportingYear &&
            learningAssessments.participationOverview !== null &&
            ((_a = learningAssessments.studentAssessmentProgressByTerm) === null || _a === void 0 ? void 0 : _a.length) > 0);
    }
    function noLearningAssessments(reportingYear, learningAssessments) {
        var _a;
        return ((!reportingYear && reportingYear !== null) ||
            (!state.loader &&
                ((_a = learningAssessments.studentAssessmentProgressByTerm) === null || _a === void 0 ? void 0 : _a.length) === 0));
    }
    var queryString = window.location.search;
    var academicAndOfficesUrl = queryString.includes("locale")
        ? "/insights/academic_office/".concat(queryString)
        : "/insights/academic_office";
    function navigateToAcademicAndOffices(url) {
        scrollToTop(".home-page-header");
        navigate(url);
    }
    return (_jsxs("div", { className: "institution-insights-card paper-card", children: [_jsx("div", { className: "header-container", children: _jsxs("div", { children: [_jsx("div", { className: "institution-name flex-row", children: _jsx("div", { children: _jsx(WmButton, { buttonType: "textonly", onClick: function () {
                                        return navigateToAcademicAndOffices(academicAndOfficesUrl);
                                    }, children: _jsx("span", { className: "program-link", children: translate("app.home.programInsightsHeading") }) }) }) }), _jsx("div", { children: translate("app.home.programInsightsDesc") })] }) }), _jsxs("div", { className: "participation-overview-container", children: [state.loader && state.reportingYear && (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })), showParticipatioOverview(state.reportingYear, state.learningAssessments) && (_jsx(ParticipationOverview, { participationOverview: state.learningAssessments.participationOverview, reportingYear: state.reportingYear })), noLearningAssessments(state.reportingYear, state.learningAssessments) && _jsx(NoLearningAssessments, {})] })] }));
}
var NoLearningAssessments = function () {
    return (_jsx("div", { className: "no-assesments", children: _jsx("div", { className: "no-assesments-text", children: translate("app.home.noSlldata") }) }));
};
