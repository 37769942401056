import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useContext } from "react";
import "../../../app/App.scss";
import Header from "../elements/Header";
import translate from "src/i18n/translate";
import { AppContext } from "src/contexts/AppContext";
import { applyPolyfills, defineCustomElements } from "@watermarkinsights/ripple/dist/loader";
import PssSideNav from "./PssSideNav";
import { onScrollChangeHeader } from "../../../static/js/commons";
import ScrollableHeader from "./ScrollableHeader";
import OrgProfileContent from "./OrgProfileContent";
import ApiError from "../elements/ApiError";
import PreviewModal from "src/features/lightcast/PreviewModal";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
import { defaultOrganization } from "src/features/common/interfaces/Interface";
export default function Body(_a) {
    var setSelectedTabView = _a.setSelectedTabView, auth = _a.auth;
    var institution = useContext(AppContext).institution;
    applyPolyfills().then(function () {
        defineCustomElements();
    });
    useEffect(function () {
        setTimeout(function () { return onScrollChangeHeader(); }, 300);
    }, []);
    var orgContent;
    if (institution && institution.platformUuid != "") {
        orgContent = _jsx(OrgProfileContent, { setSelectedTabView: setSelectedTabView });
    }
    else {
        orgContent = _jsx(ApiError, { containerClasses: "api-error margin-top-api-error" });
    }
    var displayName = institution && institution.name;
    return (_jsx(_Fragment, { children: auth ? (_jsxs(_Fragment, { children: [_jsxs("main", { id: "maincontent", className: "main-body-container", children: [_jsx(Header, { displayName: displayName }), _jsx(ScrollableHeader, { displayName: displayName }), _jsxs("div", { className: "insights-hub-body flex-row", children: [_jsx(PssSideNav, { organization: null, pssOrganization: defaultOrganization }), _jsx("div", { id: "main-org-profile-container", className: "main-org-profile", children: _jsxs("div", { children: [_jsxs("div", { className: "page-banner -insights-header", children: [_jsx("div", { className: "titles", children: "Insights" }), _jsx("div", { className: "description", children: translate("app.header.description") })] }), _jsx("div", { className: "body -height", id: "tab-components", children: orgContent })] }) })] })] }), _jsx(PreviewModal, {})] })) : (_jsx(UnauthorizedComponent, {})) }));
}
