var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { InstitutionIcon } from "src/features/common/Icons";
import { useReducer } from "react";
import { useIntl } from "react-intl";
import translate from "src/i18n/translate";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "src/features/common/helper";
var defaultAction = {
    type: "",
    showInstitution: false
};
var stateReducer = function (state, action) {
    switch (action.type) {
        case "toggleInstitution":
            return __assign(__assign({}, state), { showInstitution: !state.showInstitution });
        default:
            return state;
    }
};
var initialState = {
    showInstitution: true
};
export function ContributorInstitutionInsights(_a) {
    var organizations = _a.organizations;
    var _b = useReducer(stateReducer, initialState), state = _b[0], dispatch = _b[1];
    var toggleInstitution = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "toggleInstitution" }));
    };
    var toggleInstitutionLabel = useIntl().formatMessage({
        id: "app.home.toggleInstitution"
    });
    return (_jsxs("div", { className: "my-institution-container", children: [_jsxs("div", { className: "flex-row -verticallycentered", children: [_jsx("button", { className: state.showInstitution
                            ? "expand-button mdi  mdi-menu-down"
                            : "expand-button mdi  mdi-menu-right", onClick: toggleInstitution, "aria-label": toggleInstitutionLabel }), _jsx("span", { className: "my-institution", children: translate("app.home.myInstitution") })] }), state.showInstitution && (_jsx(InstitutionCard, { organization: organizations[0] }))] }));
}
var InstitutionCard = function (_a) {
    var organization = _a.organization;
    var navigate = useNavigate();
    var queryString = window.location.search;
    var institutionInsightsUrl = queryString.includes("locale")
        ? "/insights/institution/".concat(organization === null || organization === void 0 ? void 0 : organization.institutionUuid, "/").concat(queryString)
        : "/insights/institution/".concat(organization === null || organization === void 0 ? void 0 : organization.institutionUuid);
    function navigateToUrl(url) {
        scrollToTop(".home-page-header");
        navigate(url);
    }
    return (_jsxs("div", { className: "institution-name", children: [_jsx(InstitutionIcon, {}), _jsx("div", { className: "", children: _jsx(WmButton, { buttonType: "textonly", onClick: function () { return navigateToUrl(institutionInsightsUrl); }, children: _jsx("div", { className: "instiution-link", children: organization === null || organization === void 0 ? void 0 : organization.name }) }) })] }));
};
