var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmModal, WmModalFooter, WmModalHeader, WmTagInput, WmTagOption } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import { useEffect, useReducer } from "react";
import { sendRequest, sendRequestWithPayload } from "src/common/http";
import WidgetLoader from "src/features/common/elements/WidgetLoader";
function getFullName(user) {
    return "".concat(user.givenName, " ").concat(user.middleName ? user.middleName : "", " ").concat(user.familyName);
}
var defaultAction = {
    type: "",
    personUuid: "",
    token: "",
    isShareAccessInProgress: false
};
var reducer = function (state, action) {
    switch (action.type) {
        case "addPerson":
            return __assign(__assign({}, state), { selectedPeople: state.selectedPeople.add(action.personUuid) });
        case "removePerson":
            state.selectedPeople.delete(action.personUuid);
            return __assign(__assign({}, state), { selectedPeople: state.selectedPeople });
        case "setToken":
            return __assign(__assign({}, state), { token: action.token });
        case "setIsShareAccessInProgress":
            return __assign(__assign({}, state), { isShareAccessInProgress: action.isShareAccessInProgress });
        default:
            return state;
    }
};
export function ShareAccessModal(_a) {
    var toggleShareAccessModal = _a.toggleShareAccessModal, updatePeopleWithAccess = _a.updatePeopleWithAccess, showShareAccessModal = _a.showShareAccessModal, availablePeople = _a.availablePeople, modalData = _a.modalData;
    var selectedPeopleSet = new Set();
    modalData.selectedPeople.forEach(function (person) {
        selectedPeopleSet.add(person.uuid);
    });
    var initialState = {
        selectedPeople: selectedPeopleSet,
        token: "",
        isShareAccessInProgress: false
    };
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var modalSubHeading = useIntl().formatMessage({
        id: "app.academicsAndOffices.shareAccessModal.subHeading"
    });
    var modalHeading = useIntl().formatMessage({
        id: "app.academicsAndOffices.shareAccessModal.heading"
    }, { title: modalData.title });
    var peopleWithAccess = useIntl().formatMessage({
        id: "app.academicsAndOffices.peopleWithAccess"
    });
    var placeholdar = useIntl().formatMessage({
        id: "app.academicsAndOffices.shareAccessModal.placeholdar"
    });
    var secondaryBtn = useIntl().formatMessage({
        id: "app.settings.reportingYearModal.secondaryBtn"
    });
    var primaryBtn = useIntl().formatMessage({
        id: "app.academicsAndOffices.shareAccessModal.primaryBtn"
    });
    var email = useIntl().formatMessage({
        id: "app.drilldown.email"
    });
    var name = useIntl().formatMessage({
        id: "app.academicsAndOffices.shareAccessModal.name"
    });
    var handleTagOptionSelected = function (ev) {
        dispatch(__assign(__assign({}, defaultAction), { type: "addPerson", personUuid: ev.target["data-person-uuid"] }));
        ev.target.selected = true;
    };
    var handleTagOptionDeselected = function (ev) {
        dispatch(__assign(__assign({}, defaultAction), { type: "removePerson", personUuid: ev.target["data-person-uuid"] }));
        if (ev.target.getAttribute("user-added")) {
            ev.target.remove();
        }
        else {
            ev.target.selected = false;
        }
    };
    var shareNodeAccess = function () {
        if (!state.isShareAccessInProgress) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setIsShareAccessInProgress", isShareAccessInProgress: true }));
        }
    };
    useEffect(function () {
        if (state.isShareAccessInProgress) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setToken", token: result.token }));
            };
            sendRequest({
                uri: "/export/token",
                camelize: true
            }, applyResult);
        }
    }, [state.isShareAccessInProgress]);
    useEffect(function () {
        if (state.token !== "" && state.isShareAccessInProgress) {
            shareAccessToNode();
        }
    }, [state.token, state.isShareAccessInProgress]);
    var shareAccessToNode = function () {
        var type = modalData.nodeType === "program" ? "program" : "organization";
        var formData = new FormData();
        state.selectedPeople.forEach(function (personUuid) {
            formData.append("person_uuids[]", personUuid);
        });
        formData.append("_csrf_token", state.token ? state.token : "");
        var applyResult = function () {
            toggleShareAccessModal();
            updatePeopleWithAccess(state.selectedPeople, modalData.nodeType, modalData.nodeUuid);
            dispatch(__assign(__assign({}, defaultAction), { type: "setToken", token: "" }));
            dispatch(__assign(__assign({}, defaultAction), { type: "setIsShareAccessInProgress", isShareAccessInProgress: false }));
        };
        sendRequestWithPayload({
            uri: "/sa/".concat(type, "/").concat(modalData.nodeUuid, "/share_access"),
            body: formData
        }, applyResult);
    };
    return (_jsx(_Fragment, { children: _jsxs(WmModal, { open: showShareAccessModal, id: "share-access-modal", elementToFocus: "taginputtable", "aria-label": modalHeading, onWmModalCloseTriggered: function () { return toggleShareAccessModal(); }, onWmModalSecondaryTriggered: function () { return toggleShareAccessModal(); }, onWmModalPrimaryTriggered: function () { return shareNodeAccess(); }, children: [_jsx(WmModalHeader, { heading: modalHeading, subheading: modalSubHeading }), _jsx("div", { id: "content-share-access-modal", children: state.isShareAccessInProgress ? (_jsx("div", { className: "loader-wrapper", children: _jsx(WidgetLoader, { loaderContainerClasses: "loader-container", loaderClasses: "widget-loader" }) })) : (_jsx(WmTagInput, { id: "taginputtable", "tag-input-type": "table", label: peopleWithAccess, "col-headers": "".concat(name, ",").concat(email), "col-widths": "40%,60%", "col-wrap": "wrap,trunc,wrap", placeholder: placeholdar, children: availablePeople && (_jsx(RenderSelectedPeople, { availablePeople: availablePeople, handleSeleted: handleTagOptionSelected, handleDeseleted: handleTagOptionDeselected, selectedPeople: state.selectedPeople })) })) }), _jsx(WmModalFooter, { "primary-text": primaryBtn, "secondary-text": secondaryBtn })] }) }));
}
var RenderSelectedPeople = function (_a) {
    var availablePeople = _a.availablePeople, handleSeleted = _a.handleSeleted, handleDeseleted = _a.handleDeseleted, selectedPeople = _a.selectedPeople;
    return (_jsx(_Fragment, { children: availablePeople.map(function (user) {
            return (_jsx(WmTagOption, { id: "person-".concat(user.uuid), "data-person-uuid": user.uuid, col1: getFullName(user), col2: user.email, onWmTagOptionSelected: handleSeleted, onWmTagOptionDeselected: handleDeseleted, selected: selectedPeople === null || selectedPeople === void 0 ? void 0 : selectedPeople.has(user.uuid) }, user.uuid));
        }) }));
};
