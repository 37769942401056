var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useReducer } from "react";
import { defaultTerm } from "../common/interfaces/Interface";
import Terms from "./Terms";
import translate from "src/i18n/translate";
import { sendRequest } from "../../common/http";
import StudentLearningAssesment, { defaultParticipationOverview } from "./StudentLearningAssesment";
import ProgramCurriculumEngagement from "./programCurriculumEngagement/ProgramCurriculumEngagement";
import OrganizationInsights from "./OrganizationInsights";
import EmptyStateSll from "./EmptyStateSll";
import Loader from "../common/elements/Loader";
import { AppContext } from "src/contexts/AppContext";
import { onScrollChangeBreadCrumb } from "../../static/js/commons";
var getDefaultSelectedTerm = function (terms) {
    var today = new Date().getTime();
    return terms.filter(function (t) {
        return (new Date(t.startDate).getTime() < today &&
            new Date(t.endDate).getTime() > today);
    })[0];
};
var defaultProgramAssessments = {
    participationOverview: defaultParticipationOverview,
    assessmentProgressByCourse: []
};
var defaultAction = {
    type: "",
    terms: [],
    term: defaultTerm,
    programAssessments: defaultProgramAssessments,
    showLoader: false,
    showMainLoader: false
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setTerms":
            var selectedTerm = getDefaultSelectedTerm(action.terms) || action.terms[0];
            return __assign(__assign({}, state), { terms: action.terms, selectedTerm: selectedTerm, showMainLoader: false });
        case "selectTerm":
            return __assign(__assign({}, state), { selectedTerm: action.term, showLoader: true, programAssessments: defaultProgramAssessments });
        case "setProgramAssessments":
            return __assign(__assign({}, state), { programAssessments: action.programAssessments, showLoader: false, showMainLoader: false });
        case "setNoDataFound":
            return __assign(__assign({}, state), { noDataFound: true, showLoader: false, showMainLoader: false });
        case "setLoader":
            return __assign(__assign({}, state), { showLoader: false });
        default:
            return state;
    }
};
var initialState = {
    terms: null,
    selectedTerm: defaultTerm,
    programAssessments: defaultProgramAssessments,
    showLoader: true,
    showMainLoader: true,
    noDataFound: false
};
export default function OrganizationContent(_a) {
    var platformUuid = _a.platformUuid;
    var institution = useContext(AppContext).institution;
    useEffect(function () {
        setTimeout(function () {
            onScrollChangeBreadCrumb(institution === null || institution === void 0 ? void 0 : institution.navigatedFrom);
        }, 1000);
    }, []);
    return (_jsx(_Fragment, { children: (institution === null || institution === void 0 ? void 0 : institution.isSllSysAdminIntegrated) ? (_jsx(OrganizationInsightsContent, { platformUuid: platformUuid })) : (_jsx(InsightsEmptyState, { institution: institution })) }));
}
var InsightsEmptyState = function (_a) {
    var institution = _a.institution;
    return (_jsxs(_Fragment, { children: [_jsx(OrganizationInsights, { noDataFound: true }), _jsx("div", { className: "body -height", id: "tab-components", children: _jsx("section", { className: (institution === null || institution === void 0 ? void 0 : institution.navigatedFrom) === "planning"
                        ? "body wm-grid tab-body"
                        : "body wm-grid tab-body-home", id: "section_insights", children: _jsx("section", { className: "insights insights-sll", children: _jsx("div", { className: "institution-name -bottom flex-row -justified sse-desc flex-col-sm", children: _jsx(EmptyStateSll, {}) }) }) }) })] }));
};
function OrganizationInsightsContent(_a) {
    var platformUuid = _a.platformUuid;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var institution = useContext(AppContext).institution;
    useEffect(function () {
        var applyResult = function (terms) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setTerms", terms: terms }));
        };
        sendRequest({
            uri: "/sa/terms",
            camelize: true
        }, applyResult);
    }, []);
    useEffect(function () {
        if (state.selectedTerm &&
            state.selectedTerm.uuid !== "" &&
            platformUuid &&
            platformUuid !== "") {
            var applyResult = function (programAssessmentResultSet) {
                if (programAssessmentResultSet.participationOverview &&
                    programAssessmentResultSet.assessmentProgressByCourse) {
                    dispatch(__assign(__assign({}, defaultAction), { type: "setProgramAssessments", programAssessments: {
                            participationOverview: programAssessmentResultSet.participationOverview,
                            assessmentProgressByCourse: programAssessmentResultSet.assessmentProgressByCourse
                        } }));
                }
                else {
                    dispatch(__assign(__assign({}, defaultAction), { type: "setNoDataFound" }));
                }
            };
            var onError = function (error) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setLoader", showLoader: false }));
            };
            var requestUri = "/sll/program_assessments?" +
                "term_platform_uuid=" +
                state.selectedTerm.uuid +
                "&organization_platform_uuid=" +
                platformUuid;
            sendRequest({ uri: requestUri, camelize: true }, applyResult, onError);
        }
    }, [state.selectedTerm, platformUuid]);
    var selectTerm = function (term) {
        dispatch(__assign(__assign({}, defaultAction), { type: "selectTerm", term: term }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(OrganizationInsights, { noDataFound: state.noDataFound }), state.showMainLoader && _jsx(Loader, {}), _jsx("div", { className: "body -height", id: "tab-components", children: _jsx("section", { className: (institution === null || institution === void 0 ? void 0 : institution.navigatedFrom) === "planning"
                        ? "body wm-grid tab-body"
                        : "body wm-grid tab-body-home", id: "section_insights", children: _jsxs("section", { className: "insights insights-sll", children: [_jsxs("div", { className: "institution-name -bottom flex-row -justified sse-desc flex-col-sm", children: [!state.noDataFound && (_jsx("div", { className: "helper-label", children: translate("app.continuousImprovement.title") })), state.terms && state.terms.length > 0 && !state.noDataFound && (_jsx(Terms, { terms: state.terms, selectedTerm: state.selectedTerm, selectTerm: selectTerm }, state.selectedTerm.uuid))] }), state.terms &&
                                state.terms.length > 0 &&
                                state.selectedTerm &&
                                !state.noDataFound && (_jsxs("div", { children: [_jsx(StudentLearningAssesment, { participationOverview: state.programAssessments.participationOverview, showLoader: state.showLoader }), _jsx(ProgramCurriculumEngagement, { assessmentProgressByCourse: state.programAssessments.assessmentProgressByCourse, showLoader: state.showLoader, termUuid: state.selectedTerm.uuid, platformUuid: platformUuid })] })), state.noDataFound && _jsx(EmptyStateSll, {}), state.terms && state.terms.length === 0 && (_jsxs("div", { className: "terms-empty-state-sll", children: [_jsx("div", { className: "no-terms", children: translate("app.studentLearningAssessment.noTerms") }), _jsx("div", { className: "no-terms-message", children: translate("app.studentLearningAssessment.noTermsMessage") })] }))] }) }) })] }));
}
