var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { ProgramIcon } from "src/features/common/Icons";
import { useContext, useReducer } from "react";
import { useIntl } from "react-intl";
import translate from "src/i18n/translate";
import { AppContext } from "src/contexts/AppContext";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "src/features/common/helper";
var defaultAction = {
    type: "",
    showPrograms: false
};
var stateReducer = function (state, action) {
    switch (action.type) {
        case "togglePrograms":
            return __assign(__assign({}, state), { showPrograms: !state.showPrograms });
        default:
            return state;
    }
};
var initialState = {
    showPrograms: true
};
export function ContributorProgramInsights(_a) {
    var programs = _a.programs;
    var _b = useReducer(stateReducer, initialState), state = _b[0], dispatch = _b[1];
    var toggleProgram = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "togglePrograms" }));
    };
    var toggleProgramsLabel = useIntl().formatMessage({
        id: "app.home.togglePrograms"
    });
    return (_jsxs("div", { className: "my-institution-container", children: [_jsxs("div", { className: "flex-row -verticallycentered", children: [_jsx("button", { className: state.showPrograms
                            ? "expand-button mdi  mdi-menu-down"
                            : "expand-button mdi  mdi-menu-right", onClick: toggleProgram, "aria-label": toggleProgramsLabel }), _jsx("span", { className: "my-institution", children: translate("app.home.myPrograms") })] }), _jsx("div", { id: "program-list-container", children: state.showPrograms &&
                    programs.map(function (program) {
                        return (_jsx(ProgramCards, { program: program }, "".concat(program.uuid, " ").concat(program.code)));
                    }) })] }));
}
var ProgramCards = function (_a) {
    var program = _a.program;
    var institution = useContext(AppContext).institution;
    var navigate = useNavigate();
    var queryString = window.location.search;
    var programUrl = queryString.includes("locale")
        ? "/insights/institution/".concat(institution === null || institution === void 0 ? void 0 : institution.platformUuid, "/organization/").concat(program.uuid, "/").concat(queryString)
        : "/insights/institution/".concat(institution === null || institution === void 0 ? void 0 : institution.platformUuid, "/organization/").concat(program.uuid);
    function navigateToUrl(url) {
        scrollToTop(".home-page-header");
        navigate(url);
    }
    return (_jsxs("div", { className: "institution-name", children: [_jsx(ProgramIcon, {}), _jsx("div", { className: "program-button", children: _jsx(WmButton, { buttonType: "textonly", onClick: function () { return navigateToUrl(programUrl); }, children: _jsx("div", { className: "instiution-link", children: program.name }) }) })] }));
};
