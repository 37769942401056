var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useReducer, useRef } from "react";
import { useIntl } from "react-intl";
import { WorkflowTaskStep } from "./WorkflowTaskStep";
import useSmallScreenView from "src/features/common/useSmallScreenView";
import { WorkflowTaskStepSmallScreen } from "./WorkflowTaskStepSmallScreen";
import { WorkflowTaskStepTooltip } from "./WorkflowTaskStepTooltip";
import { AppContext } from "src/contexts/AppContext";
var defaultAction = {
    type: "",
    showSteps: false,
    showTooltip: false,
    tooltipData: null,
    showTooltipStepIndex: null,
    showTooltipSmallScreen: false,
    tooltipDataSmallScreen: null,
    currentStepIndex: -1,
    clickedStep: -1
};
var stateReducer = function (state, action) {
    var _a, _b;
    switch (action.type) {
        case "toggleSteps":
            return __assign(__assign({}, state), { showSteps: !state.showSteps });
        case "openTooltip":
            return __assign(__assign({}, state), { showTooltip: true });
        case "closeTooltip":
            return __assign(__assign({}, state), { showTooltip: false, clickedStep: -1 });
        case "setTooltipData": {
            return __assign(__assign({}, state), { tooltipData: action.tooltipData });
        }
        case "setTooltipIndexSmallScreen": {
            return __assign(__assign({}, state), { showTooltipStepIndex: action.showTooltipStepIndex });
        }
        case "toggleSmallScreenTooltip": {
            return __assign(__assign({}, state), { showTooltipSmallScreen: !state.showTooltipSmallScreen });
        }
        case "setTooltipDataSmallScreen": {
            return __assign(__assign({}, state), { tooltipDataSmallScreen: action.tooltipDataSmallScreen });
        }
        case "setCurrentStepIndex":
            return __assign(__assign({}, state), { currentStepIndex: (_a = action.currentStepIndex) !== null && _a !== void 0 ? _a : -1 });
        case "setClickedstepIndex":
            return __assign(__assign({}, state), { clickedStep: (_b = action.clickedStep) !== null && _b !== void 0 ? _b : -1 });
        default:
            return state;
    }
};
export function WorkFlowTaskProgress(_a) {
    var workflowTask = _a.workflowTask, totalWorkflows = _a.totalWorkflows;
    var initialState = {
        showSteps: totalWorkflows === 1,
        showTooltip: false,
        tooltipData: null,
        showTooltipStepIndex: null,
        tooltipDataSmallScreen: null,
        showTooltipSmallScreen: false,
        currentStepIndex: -1,
        clickedStep: -1
    };
    var _b = useReducer(stateReducer, initialState), state = _b[0], dispatch = _b[1];
    var sysUser = useContext(AppContext).sysUser;
    var isAdminUser = sysUser === null || sysUser === void 0 ? void 0 : sysUser.roles.includes("administrator");
    var isSmallScreen = useSmallScreenView(1124);
    var tooltipRef = useRef(null);
    function toggleSteps() {
        dispatch(__assign(__assign({}, defaultAction), { type: "toggleSteps" }));
    }
    var toggleStepsLabel = useIntl().formatMessage({
        id: "app.home.reviewsInProgress.toggleSteps"
    });
    var workflowTaskLabel = useIntl().formatMessage({ id: "app.home.reviewsInProgress.workflowTaskLabel" }, { title: workflowTask.title });
    function showSmallScreenSteps(showSteps) {
        return isSmallScreen && showSteps;
    }
    function removeTabIndex(stepContainer, currentStepIndex) {
        if (stepContainer && currentStepIndex !== -1) {
            var previousElement = stepContainer.children[currentStepIndex];
            previousElement.tabIndex = -1;
        }
    }
    function focusRight(totalSteps, stepContainer, currentStepIndex, event) {
        if (totalSteps > 0) {
            dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
            var nextIndex_1 = (currentStepIndex + 1) % totalSteps;
            var nextElement_1 = stepContainer.children[nextIndex_1];
            nextElement_1.tabIndex = 0;
            nextElement_1.focus();
            dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: nextIndex_1 }));
            setTimeout(function () {
                openTooltip(event, nextIndex_1, nextElement_1);
            }, 300);
        }
    }
    function focusLeft(totalSteps, stepContainer, currentStepIndex, event) {
        if (totalSteps > 0) {
            dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
            var prevIndex_1 = currentStepIndex === -1
                ? totalSteps - 1
                : (currentStepIndex - 1 + totalSteps) % totalSteps;
            var prevElement_1 = stepContainer.children[prevIndex_1];
            prevElement_1.tabIndex = 0;
            prevElement_1.focus();
            dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: prevIndex_1 }));
            setTimeout(function () {
                openTooltip(event, prevIndex_1, prevElement_1);
            }, 300);
        }
    }
    var handleKeyDown = function (event) {
        var steps = workflowTask.steps;
        var totalSteps = steps.length;
        var currentStepIndex = state.clickedStep;
        var parent = event.target.closest(".task-progress-container");
        var stepContainer = parent.querySelector(".steps-container");
        removeTabIndex(stepContainer, currentStepIndex);
        switch (event.key) {
            case "ArrowRight":
                event.preventDefault();
                focusRight(totalSteps, stepContainer, currentStepIndex, event);
                break;
            case "ArrowLeft":
                event.preventDefault();
                focusLeft(totalSteps, stepContainer, currentStepIndex, event);
                break;
            default:
                break;
        }
        if (event.key === "Tab" && event.shiftKey) {
            dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
        }
    };
    function expandStepOnClick(stepIndex) {
        setTimeout(function () {
            dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: stepIndex }));
        }, 350);
    }
    function openTooltip(event, stepIndex, focusedElement) {
        setTimeout(function () {
            var selectedStep = workflowTask.steps.find(function (step) { return step.stepIndex === stepIndex; });
            var element = null;
            if (event.key && focusedElement) {
                element = focusedElement;
            }
            else {
                element = event.target.closest(".step");
            }
            var rect = element.getBoundingClientRect();
            var parentElement = element.parentNode;
            var parentElementRect = parentElement.getBoundingClientRect();
            var left = isAdminUser
                ? rect.left - 355 + rect.width / 2
                : rect.left - 310 + rect.width / 2;
            var carrotPositionX = isAdminUser
                ? rect.left - 181 + rect.width / 2
                : rect.left - 130 + rect.width / 2;
            if (left < 0) {
                left = 0;
            }
            if (left > parentElementRect.width - 363) {
                left = parentElementRect.width - 363;
            }
            if (selectedStep && element) {
                var submittedDate = selectedStep.submittedDate, assignees = selectedStep.assignees, dueDate = selectedStep.dueDate, state_1 = selectedStep.state, stepIndex_1 = selectedStep.stepIndex, name = selectedStep.name, daysUntilDue = selectedStep.daysUntilDue, dueDateType = selectedStep.dueDateType, id = selectedStep.id;
                var tooltipData = {
                    totalSteps: workflowTask.steps.length,
                    name: name,
                    stepIndex: stepIndex_1,
                    submittedDate: submittedDate,
                    assignees: assignees,
                    dueDate: dueDate,
                    state: state_1,
                    positionX: left < 0 ? 0 : left,
                    daysUntilDue: daysUntilDue,
                    carrotPosition: carrotPositionX,
                    dueDateType: dueDateType,
                    id: id
                };
                dispatch(__assign(__assign({}, defaultAction), { type: "openTooltip" }));
                dispatch(__assign(__assign({}, defaultAction), { type: "setTooltipData", tooltipData: tooltipData }));
            }
        }, 400);
    }
    function openTooltipSmallScreen(index) {
        if (state.showTooltipStepIndex === index) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setTooltipIndexSmallScreen", showTooltipStepIndex: null }));
        }
        else {
            var selectedStep = workflowTask.steps.find(function (step) { return step.stepIndex === index; });
            if (selectedStep) {
                var submittedDate = selectedStep.submittedDate, assignees = selectedStep.assignees, dueDate = selectedStep.dueDate, state_2 = selectedStep.state, stepIndex_2 = selectedStep.stepIndex, name = selectedStep.name, daysUntilDue = selectedStep.daysUntilDue, dueDateType = selectedStep.dueDateType, id = selectedStep.id;
                var tooltipData = {
                    totalSteps: workflowTask.totalSteps,
                    name: name,
                    stepIndex: stepIndex_2,
                    submittedDate: submittedDate,
                    assignees: assignees,
                    dueDate: dueDate,
                    state: state_2,
                    daysUntilDue: daysUntilDue,
                    dueDateType: dueDateType,
                    id: id
                };
                setTimeout(function () {
                    dispatch(__assign(__assign({}, defaultAction), { type: "setTooltipIndexSmallScreen", showTooltipStepIndex: stepIndex_2 }));
                }, 300);
                dispatch(__assign(__assign({}, defaultAction), { type: "setTooltipDataSmallScreen", tooltipDataSmallScreen: tooltipData }));
            }
        }
    }
    function checkIfClickedElementIsStep(element) {
        var _a, _b;
        var stepContainer = (_b = (_a = tooltipRef.current) === null || _a === void 0 ? void 0 : _a.closest(".task-progress-container")) === null || _b === void 0 ? void 0 : _b.querySelector(".steps-container");
        var stepsArray = Array.from((stepContainer === null || stepContainer === void 0 ? void 0 : stepContainer.children) || []);
        return stepsArray.some(function (step) { return step.contains(element); });
    }
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (tooltipRef.current &&
                !tooltipRef.current.contains(event.target) &&
                !checkIfClickedElementIsStep(event.target)) {
                setTimeout(function () {
                    dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
                }, 300);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (_jsxs("div", { className: totalWorkflows > 1 && !state.showSteps
            ? "task-progress-container tabbing  reduced-padding "
            : "task-progress-container tabbing", tabIndex: 0, "aria-label": workflowTaskLabel, onKeyDown: function (event) { return handleKeyDown(event); }, onFocus: function () {
            if (!state.showTooltip) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: -1 }));
            }
        }, children: [_jsxs("div", { className: "task-name", children: [totalWorkflows > 1 && (_jsx("button", { className: state.showSteps
                            ? "expand-button mdi  mdi-menu-down"
                            : "expand-button mdi  mdi-menu-right", onClick: toggleSteps, "aria-label": toggleStepsLabel, onBlur: function () { return dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" })); } })), workflowTask.title] }), !isSmallScreen && (_jsx("div", { className: "steps-container", children: workflowTask.steps.map(function (step) {
                    return (_jsx(WorkflowTaskStep, { step: step, allExpanded: workflowTask.steps.length <= 4, openTooltip: openTooltip, clickedStep: state.clickedStep, expandStepOnClick: expandStepOnClick }, step.id));
                }) })), state.showTooltip && state.tooltipData && (_jsx(WorkflowTaskStepTooltip, { tooltipData: state.tooltipData, tooltipRef: tooltipRef, closeTooltip: function () {
                    dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
                } })), showSmallScreenSteps(state.showSteps) && (_jsx("div", { className: "steps-container", children: workflowTask.steps.map(function (step) {
                    var _a;
                    return (_jsx(WorkflowTaskStepSmallScreen, { step: step, showStepIndex: (_a = state.showTooltipStepIndex) !== null && _a !== void 0 ? _a : -1, tooltipData: state.tooltipDataSmallScreen, showTooltipSmallScreen: state.showTooltipSmallScreen, openTooltip: openTooltipSmallScreen }, "ss-".concat(step.id)));
                }) }))] }));
}
