import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from "react";
import "./Header.scss";
import { AppContext } from "../../../contexts/AppContext";
import translate from "src/i18n/translate";
export default function ScrollableHeader(_a) {
    var displayName = _a.displayName;
    var _b = useContext(AppContext), institution = _b.institution, sysUser = _b.sysUser, breadcrumb = _b.breadcrumb;
    var showHomeBreadcrum = breadcrumb === "home" ||
        (sysUser &&
            sysUser.roles.includes("contributor") &&
            sysUser.roles.length === 1);
    var homeUrl = showHomeBreadcrum
        ? "/insights/home"
        : "/insights/academic_office";
    var headerBody = function () {
        if (institution) {
            var REDIRECT_URI = runConfig.REDIRECT_URI;
            var planningURI = institution.rootOrgUuid
                ? "".concat(REDIRECT_URI, "/institution/").concat(institution.pssUuid, "/org_identity/").concat(institution.rootOrgUuid)
                : "".concat(REDIRECT_URI);
            var redirect = function (url) {
                window.location.replace("".concat(url));
            };
            return (_jsx("section", { id: "scroll-header", className: "immersive-header header flexible-header orgIdentity-header -no-height hidden", children: _jsx("div", { className: "page-header", children: _jsxs("section", { className: "bread-crumbs -crumb", children: [_jsx("a", { href: institution.navigatedFrom === "planning"
                                    ? REDIRECT_URI
                                    : homeUrl, id: "link-0-scrolled-page-banner", className: institution.navigatedFrom === "planning"
                                    ? "link pss-link"
                                    : "link", children: _jsx("span", { className: institution.navigatedFrom === "planning"
                                        ? "breadcrumb-title pss-breadcrumb-title"
                                        : "breadcrumb-title", children: institution.navigatedFrom === "planning" || showHomeBreadcrum
                                        ? translate("app.mainnav.home")
                                        : translate("app.mainnav.academicsAndOffices") }) }), _jsx("span", { className: "org-name", children: displayName })] }) }) }));
        }
    };
    return _jsx(_Fragment, { children: headerBody() });
}
