var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "../../common/elements/Header.scss";
import { useContext, useEffect, useReducer } from "react";
import translate from "src/i18n/translate";
import { AppContext } from "src/contexts/AppContext";
import { MyTasks } from "./MyTasks";
import OrganizationProgramInsights from "./OrganizationProgramInsights";
import { defacultFsDeeplinks } from "src/features/common/interfaces/Interface";
import { checkIfFsProductIntegrated, getCurrentReportingYear } from "src/features/common/helper";
import { sendRequest } from "src/common/http";
import { WmButton } from "@watermarkinsights/ripple-react";
import { ReviewsInProgress } from "./ReviewsInProgress";
var defaultAction = {
    type: "",
    currentReportingYear: null,
    fsDeeplinks: defacultFsDeeplinks
};
var initialState = {
    currentReportingYear: null,
    fsDeeplinks: defacultFsDeeplinks
};
var stateReducer = function (state, action) {
    switch (action.type) {
        case "setCurrentReportingYear":
            return __assign(__assign({}, state), { currentReportingYear: action.currentReportingYear });
        case "setFsDeeplinks":
            return __assign(__assign({}, state), { fsDeeplinks: action.fsDeeplinks });
        default:
            return state;
    }
};
var navigateTo = function (url) {
    window.location.href = url;
};
export default function HomeComponent() {
    var _a = useContext(AppContext), sysUser = _a.sysUser, integratedProducts = _a.integratedProducts;
    var _b = useReducer(stateReducer, initialState), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        if (sysUser && sysUser.roles.includes("administrator")) {
            var applyResult = function (result) {
                var currentReportingYear = getCurrentReportingYear(result);
                dispatch(__assign(__assign({}, defaultAction), { type: "setCurrentReportingYear", currentReportingYear: currentReportingYear }));
            };
            sendRequest({ uri: "/sa/reporting_years", camelize: true }, applyResult);
        }
    }, [sysUser]);
    useEffect(function () {
        if (checkIfFsProductIntegrated(integratedProducts)) {
            var applyResult = function (result) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setFsDeeplinks", fsDeeplinks: result }));
            };
            sendRequest({
                uri: "/faculty_success/deeplinks",
                camelize: true
            }, applyResult);
        }
    }, [integratedProducts]);
    return (_jsx(_Fragment, { children: sysUser && (_jsx("main", { id: "maincontent", className: "main-content-home", children: _jsxs("div", { className: "home-page-header", children: [_jsxs("div", { className: sysUser.roles.includes("administrator")
                            ? "home-title-container"
                            : "home-title-container container-padding", children: [_jsx("div", { className: "home base-font", children: translate("app.home") }), _jsx("div", { className: "home-title", children: translate("app.home.title", {
                                    username: "".concat(sysUser.firstName)
                                }) }), _jsx("div", { className: "home-title-description", children: translate("app.home.titleDesc") }), state.fsDeeplinks.activities && (_jsx(AddActivity, { activitiesLink: state.fsDeeplinks.activities }))] }), _jsx(MyTasks, {}), checkIfFsProductIntegrated(integratedProducts) && (_jsx(ReviewsInProgress, {})), _jsx(OrganizationProgramInsights, { currentReportingYear: state.currentReportingYear })] }) })) }));
}
var AddActivity = function (_a) {
    var activitiesLink = _a.activitiesLink;
    return (_jsx("div", { className: "add-activity-button", children: _jsx(WmButton, { buttonType: "primary", onClick: function () { return navigateTo(activitiesLink); }, children: translate("app.home.fs.activity") }) }));
};
