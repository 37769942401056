import { useState, useEffect } from "react";
var useSmallScreenView = function (breakpoint) {
    var _a = useState(window.innerWidth <= breakpoint), isSmallScreen = _a[0], setIsSmallScreen = _a[1];
    useEffect(function () {
        var handleResize = function () {
            setIsSmallScreen(window.innerWidth <= breakpoint);
        };
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return isSmallScreen;
};
export default useSmallScreenView;
