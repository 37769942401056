var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "../../common/elements/Header.scss";
import { applyPolyfills, defineCustomElements } from "@watermarkinsights/ripple/dist/loader";
import { useContext, useEffect, useReducer } from "react";
import { sendRequest } from "../../../common/http";
import translate from "src/i18n/translate";
import WidgetLoader from "../../common/elements/WidgetLoader";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
import { WmButton } from "@watermarkinsights/ripple-react";
import { ShareAccessModal } from "./ShareAccessModal";
import { AppContext } from "src/contexts/AppContext";
import { Link } from "react-router-dom";
import { scrollToTop } from "src/features/common/helper";
import ApiError from "src/features/common/elements/ApiError";
var defaultModalData = {
    title: "",
    nodeUuid: "",
    nodeType: "",
    selectedPeople: []
};
var defaultAction = {
    type: "",
    programs: [],
    showPrograms: true,
    institution: null,
    availablePeople: [],
    modalData: defaultModalData,
    uuid: "",
    nodeType: "",
    peopleWithAccess: [],
    apiError: false
};
function updatePrograms(programs, uuid, peopleWithAccess) {
    var _a;
    return ((_a = programs === null || programs === void 0 ? void 0 : programs.map(function (progm) {
        return progm.uuid === uuid
            ? __assign(__assign({}, progm), { peopleWithAccess: peopleWithAccess }) : progm;
    })) !== null && _a !== void 0 ? _a : null);
}
function updateInstitution(institution, peopleWithAccess) {
    return institution
        ? __assign(__assign({}, institution), { peopleWithAccess: peopleWithAccess }) : null;
}
function actionUpdatePeopleWithAccess(state, action) {
    if (action.nodeType === "program") {
        return __assign(__assign({}, state), { programs: updatePrograms(state.programs, action.uuid, action.peopleWithAccess) });
    }
    else {
        return __assign(__assign({}, state), { institution: updateInstitution(state.institution, action.peopleWithAccess) });
    }
}
var reducer = function (state, action) {
    switch (action.type) {
        case "updatePeopleWithAccess":
            return actionUpdatePeopleWithAccess(state, action);
        case "setPrograms":
            return __assign(__assign({}, state), { programs: action.programs, institution: action.institution, showLoader: false });
        case "showPrograms":
            return __assign(__assign({}, state), { showPrograms: !state.showPrograms });
        case "toggleShareAccessModal":
            return __assign(__assign({}, state), { showShareAccessModal: !state.showShareAccessModal });
        case "setPeople":
            return __assign(__assign({}, state), { availablePeople: action.availablePeople });
        case "setModalData":
            return __assign(__assign({}, state), { modalData: action.modalData });
        case "setApiError":
            return __assign(__assign({}, state), { apiError: true, showLoader: false });
        default:
            return state;
    }
};
export default function AcademicsAndOffices(_a) {
    var auth = _a.auth;
    applyPolyfills().then(function () {
        defineCustomElements();
    });
    var initialState = {
        showPrograms: true,
        programs: null,
        institution: null,
        showLoader: true,
        availablePeople: null,
        showShareAccessModal: false,
        modalData: defaultModalData,
        apiError: false
    };
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setPrograms", programs: result.programs, institution: result.institution }));
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultAction), { type: "setApiError" }));
        };
        auth &&
            sendRequest({ uri: "/sa/programs", camelize: true }, applyResult, onError);
    }, [auth]);
    useEffect(function () {
        var applyResult = function (result) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setPeople", availablePeople: result }));
        };
        var onError = function () {
            dispatch(__assign(__assign({}, defaultAction), { type: "setApiError" }));
        };
        auth &&
            sendRequest({ uri: "/sa/people", camelize: true }, applyResult, onError);
    }, [auth]);
    function expandTable() {
        dispatch(__assign(__assign({}, defaultAction), { type: "showPrograms" }));
    }
    function updatePeopleWithAccess(uuids, type, nodeUuid) {
        var peopleWithAccess = new Array(0);
        uuids.forEach(function (uuid) {
            var _a;
            var person = (_a = state.availablePeople) === null || _a === void 0 ? void 0 : _a.find(function (person) { return person.uuid === uuid; });
            person && peopleWithAccess.push(person);
        });
        dispatch(__assign(__assign({}, defaultAction), { type: "updatePeopleWithAccess", uuid: nodeUuid, nodeType: type, peopleWithAccess: peopleWithAccess }));
    }
    function toggleShareAccessModal(modalData) {
        dispatch(__assign(__assign({}, defaultAction), { type: "toggleShareAccessModal" }));
        if (modalData) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setModalData", modalData: modalData }));
        }
    }
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    return (_jsx(_Fragment, { children: auth ? (_jsxs("div", { className: "home-page-header", children: [_jsxs("div", { className: "home-title-container", children: [_jsx("div", { className: "home-title", children: translate("app.mainnav.academicsAndOffices") }), _jsx("div", { className: "home-title-description", children: translate("app.home.org.desc") })] }), state.showLoader && (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })), state.apiError && _jsx(ApiError, { containerClasses: "api-error" }), !state.showLoader && !state.apiError && (_jsxs("div", { className: "org-node paper-card -nopadding org-management", role: "grid", "aria-readonly": "true", children: [_jsxs("div", { className: "orgnodeheader grid-row", role: "row", children: [_jsx("div", { className: "column", role: "columnheader", children: translate("app.drilldown.organization") }), _jsx("div", { className: "column", role: "columnheader", children: translate("app.academicsAndOffices.peopleWithAccess") })] }), state.institution && (_jsx("div", { className: "orgchild treeitem extended", "aria-expanded": "true", children: _jsx(InstitutionRow, { institution: state.institution, showPrograms: state.showPrograms, expandTable: expandTable, toggleShareAccessModal: toggleShareAccessModal }) })), _jsx("div", { className: "orgchild treeitem extended", "aria-expanded": "true", children: state.programs &&
                                state.programs.map(function (program, index) {
                                    return (_jsx(ProgramRow, { program: program, showPrograms: state.showPrograms, toggleShareAccessModal: toggleShareAccessModal }, "".concat(program.uuid, " ").concat(index, "  ").concat(program.code)));
                                }) }), state.showShareAccessModal && (_jsx(ShareAccessModal, { toggleShareAccessModal: toggleShareAccessModal, updatePeopleWithAccess: updatePeopleWithAccess, showShareAccessModal: state.showShareAccessModal, availablePeople: state.availablePeople, modalData: state.modalData }))] }))] })) : (_jsx(UnauthorizedComponent, {})) }));
}
var queryString = window.location.search;
function ProgramRow(_a) {
    var program = _a.program, showPrograms = _a.showPrograms, toggleShareAccessModal = _a.toggleShareAccessModal;
    var _b = useContext(AppContext), ctxInstitution = _b.institution, setBreadcrumb = _b.setBreadcrumb;
    return (_jsxs("div", { className: showPrograms
            ? "orgnode grid-row program-level"
            : "orgnode grid-row program-level expand-program", id: "program-node", role: "row", children: [_jsx("div", { className: "column", role: "gridcell", children: _jsxs("div", { className: "focus-space", children: [_jsx("h3", { className: "treeitemlabel", children: _jsx(Link, { to: queryString.includes("locale")
                                    ? "/insights/institution/".concat(ctxInstitution === null || ctxInstitution === void 0 ? void 0 : ctxInstitution.platformUuid, "/organization/").concat(program.uuid, "/").concat(queryString)
                                    : "/insights/institution/".concat(ctxInstitution === null || ctxInstitution === void 0 ? void 0 : ctxInstitution.platformUuid, "/organization/").concat(program.uuid), id: "toolsNavItemLink", className: "program-link", onClick: function () {
                                    scrollToTop(".home-page-header");
                                    setBreadcrumb("academic-office");
                                }, children: program.name }) }), _jsx("p", { className: "node-info", children: _jsxs("span", { className: "infoheader", children: [translate("app.home.org.type"), _jsx("span", { className: "infodesc", children: translate("app.strategy.topProgramsByCatalog.program") })] }) }), _jsx("p", { className: "node-info", children: _jsxs("span", { className: "infoheader", children: [translate("app.home.org.code"), _jsx("span", { className: "infodesc", children: program.code })] }) })] }) }), _jsx("div", { className: "column cell-program", role: "gridcell", children: _jsx(ShareAccessButton, { node: program, toggleShareAccessModal: toggleShareAccessModal }) })] }));
}
function InstitutionRow(_a) {
    var institution = _a.institution, showPrograms = _a.showPrograms, expandTable = _a.expandTable, toggleShareAccessModal = _a.toggleShareAccessModal;
    var _b = useContext(AppContext), ctxInstitution = _b.institution, setBreadcrumb = _b.setBreadcrumb;
    return (_jsxs("div", { className: "orgnode grid-row", id: "program-node", role: "row", children: [_jsxs("div", { className: "column", role: "gridcell", children: [_jsx("button", { className: showPrograms
                            ? "expand-button mdi  mdi-menu-down"
                            : "expand-button mdi  mdi-menu-right", "aria-label": "Toggle tree view of University Expanded", onClick: expandTable }), _jsxs("div", { className: "focus-space", children: [_jsx("h3", { className: "treeitemlabel program-name", children: _jsx(Link, { to: queryString.includes("locale")
                                        ? "/insights/institution/".concat(ctxInstitution === null || ctxInstitution === void 0 ? void 0 : ctxInstitution.platformUuid, "/").concat(queryString)
                                        : "/insights/institution/".concat(ctxInstitution === null || ctxInstitution === void 0 ? void 0 : ctxInstitution.platformUuid), id: "toolsNavItemLink", className: "program-link", onClick: function () {
                                        scrollToTop(".home-page-header");
                                        setBreadcrumb("academic-office");
                                    }, children: institution === null || institution === void 0 ? void 0 : institution.name }) }), _jsx("p", { className: "node-info", children: _jsxs("span", { className: "infoheader", children: [translate("app.home.org.type"), _jsx("span", { className: "infodesc", children: translate("app.home.org.institution") })] }) }), _jsx("p", { className: "node-info", children: _jsxs("span", { className: "infoheader", children: [translate("app.home.org.code"), _jsx("span", { className: "infodesc", children: institution === null || institution === void 0 ? void 0 : institution.code })] }) })] })] }), _jsx("div", { className: "column cell", role: "gridcell", children: _jsx(ShareAccessButton, { node: institution, toggleShareAccessModal: toggleShareAccessModal }) })] }));
}
function ShareAccessButton(_a) {
    var _b;
    var node = _a.node, toggleShareAccessModal = _a.toggleShareAccessModal;
    return (_jsx(_Fragment, { children: node && ((_b = node === null || node === void 0 ? void 0 : node.peopleWithAccess) === null || _b === void 0 ? void 0 : _b.length) > 0 ? (_jsx(WmButton, { "button-type": "textonly", class: "-textonly hydrated", onClick: function () {
                return toggleShareAccessModal(__assign(__assign({}, defaultModalData), { title: node.name, nodeType: node.type, nodeUuid: node.uuid, selectedPeople: node.peopleWithAccess }));
            }, children: _jsx(PeopleNames, { node: node }) })) : (_jsx(WmButton, { "button-type": "textonly", icon: "f014", class: "-textonly hydrated", onClick: function () {
                return node &&
                    toggleShareAccessModal(__assign(__assign({}, defaultModalData), { title: node.name, nodeType: node.type, nodeUuid: node.uuid, selectedPeople: [] }));
            }, children: translate("app.academicsAndOffices.shareAccess") })) }));
}
function PeopleNames(_a) {
    var node = _a.node;
    return (_jsxs(_Fragment, { children: [node.peopleWithAccess
                .slice(0, 5)
                .map(function (person, index) {
                return (_jsx(PersonWithAccess, { person: person, peopleWithAccess: node.peopleWithAccess, index: index }, "person-with-access-".concat(person.uuid)));
            }), node.peopleWithAccess.length > 5 &&
                " +".concat(node.peopleWithAccess.length - 5, " more")] }));
}
var PersonWithAccess = function (_a) {
    var person = _a.person, index = _a.index, peopleWithAccess = _a.peopleWithAccess;
    return (_jsxs(_Fragment, { children: ["".concat(person.givenName, " ").concat(person.middleName ? person.middleName : "", " ").concat(person.familyName), index < peopleWithAccess.length - 1 && ", "] }));
};
