var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import translate from "src/i18n/translate";
import WidgetLoader from "../../common/elements/WidgetLoader";
import { useReducer } from "react";
import { openFlyout } from "../../../static/js/commons";
import ViewCourseDetails from "../ViewCourseDetails";
import { sendRequest } from "../../../common/http";
import RenderCourseAssessments from "./RenderCourseAssessments";
var initialState = {
    isFlyoutOpen: false,
    showTable: false,
    courseCode: "",
    courseName: "",
    hierarchy: null
};
var defaultAction = {
    type: "",
    isFlyoutOpen: false,
    courseCode: "",
    courseName: "",
    data: {}
};
var reducer = function (state, action) {
    switch (action.type) {
        case "toggleFlyout":
            return __assign(__assign({}, state), { isFlyoutOpen: !state.isFlyoutOpen, hierarchy: null });
        case "setCourseDetails":
            return __assign(__assign({}, state), { showTable: true, hierarchy: action.data.studentAssessmentProgressBySection });
        case "setFlyoutData":
            return __assign(__assign({}, state), { courseCode: action.courseCode, courseName: action.courseName });
        default:
            return state;
    }
};
var ProgramCurriculumEngagement = function (_a) {
    var assessmentProgressByCourse = _a.assessmentProgressByCourse, showLoader = _a.showLoader, termUuid = _a.termUuid, platformUuid = _a.platformUuid;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var setIsFlyoutOpen = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "toggleFlyout" }));
    };
    var openFlyoutCourseDetails = function (assessmentProgressByCourse) {
        var applyResult = function (data) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setCourseDetails", data: data }));
        };
        var requestUri = "/sll/program_course_details?" +
            "term_platform_uuid=" +
            termUuid +
            "&course_platform_uuid=" +
            assessmentProgressByCourse.uuid +
            "&organization_platform_uuid=" +
            platformUuid;
        sendRequest({ uri: requestUri, camelize: true }, applyResult);
        setTimeout(function () {
            return openFlyout("drill-down-view", "course-charts", "course-details-flyout-close-btn");
        }, 400);
        dispatch(__assign(__assign({}, defaultAction), { type: "setFlyoutData", courseCode: assessmentProgressByCourse.code, courseName: assessmentProgressByCourse.name }));
        setIsFlyoutOpen();
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "wm-grid-row program-curriculum-engagement", children: [_jsx("span", { className: "sr-only faculty-loader-status", role: "status", "aria-live": "assertive" }), _jsx("div", { className: "grid-container wm-grid-col-12 wm-grid-col-md-10", children: _jsxs("div", { className: "paper-card paper-card-height-program", children: [_jsx(Header, { assessmentProgressByCourseLength: assessmentProgressByCourse.length }), _jsx("div", { className: "", children: showLoader ? (_jsx(WidgetLoader, { loaderContainerClasses: "loader", loaderClasses: "content" })) : (_jsx(RenderCourseAssessments, { assessmentProgressByCourse: assessmentProgressByCourse, openFlyoutCourseDetails: openFlyoutCourseDetails })) })] }) })] }), state.isFlyoutOpen && (_jsxs(_Fragment, { children: [_jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 }), _jsx(ViewCourseDetails, { courseName: state.courseName, courseCode: state.courseCode, setIsFlyoutOpen: setIsFlyoutOpen, showTable: state.showTable, hierarchy: state.hierarchy }), _jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-bottom", tabIndex: 0 })] }))] }));
};
var Header = function (_a) {
    var assessmentProgressByCourseLength = _a.assessmentProgressByCourseLength;
    return (_jsx("div", { className: "header", children: _jsxs("div", { className: "info", children: [_jsx("h3", { className: "title", children: translate("app.programCurriculumEngagement.widgetLabel") }), _jsxs("div", { className: "flex-row -justified program-engagement-desc", children: [_jsx("p", { className: "description", children: translate("app.programCurriculumEngagement.widgetDescription") }), assessmentProgressByCourseLength > 0 && _jsx(Legends, {})] })] }) }));
};
var Legends = function () {
    return (_jsxs("div", { className: "legend flex-row", children: [_jsxs("div", { className: "completed flex-row", children: [_jsx("span", { className: "completed-box" }), _jsxs("span", { children: [translate("app.drilldown.completed"), " "] })] }), _jsxs("div", { className: "not-completed flex-row", children: [_jsx("span", { className: "not-completed-box" }), _jsx("span", { children: translate("app.programCurriculumEngagement.notCompleted") })] })] }));
};
export default ProgramCurriculumEngagement;
