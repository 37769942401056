import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmNavigator, WmSelect, WmOption } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import translate from "../../../../src/i18n/translate";
import { toggleNavOrgMgt } from "../../../static/js/commons";
import { useContext } from "react";
import { AppContext } from "src/contexts/AppContext";
var API_ROOT = runConfig.API_ROOT;
var openHelpCentreLink = function () {
    return window.open("".concat(API_ROOT, "/zendesk/login"), "_blank");
};
export default function InsightsHeader(_a) {
    var hideNavigatorSideNav = _a.hideNavigatorSideNav;
    var _b = useContext(AppContext), institution = _b.institution, sysUser = _b.sysUser;
    var institutionLogo = useIntl().formatMessage({
        id: "app.header.logo"
    }, { institutionName: institution === null || institution === void 0 ? void 0 : institution.name });
    return (_jsx("div", { className: "top-bar topbar-overlay top-bar-contributor", children: institution && (_jsxs(_Fragment, { children: [sysUser && sysUser.roles.includes("administrator") && (_jsxs("button", { id: "mobile-nav-toggle", className: hideNavigatorSideNav ? "icononly show-toggle-nav" : "icononly", onClick: toggleNavOrgMgt, children: [_jsx("span", { className: "sr-only tooltip -left", children: translate("app.showNavigation") }), _jsx("span", { className: "hamburger-inner" })] })), _jsx("h1", { className: "logo", id: institution.fsLogoUrl ? "header-logo" : "", children: _jsx("img", { className: hideNavigatorSideNav
                            ? "institution-logo ml-20"
                            : "institution-logo", src: institution.fsLogoUrl
                            ? institution.fsLogoUrl
                            : "/images/Insights-Hub.svg", alt: institutionLogo }) }), sysUser && (_jsx(NavigationMenu, { institution: institution, user: sysUser }))] })) }));
}
var HelpCenter = function () {
    var helpCenterlink = useIntl().formatMessage({
        id: "app.appBar.helpCenterlink"
    });
    return (_jsx("li", { className: "products dropdown-select", children: _jsx("button", { className: "header-help-icon", tabIndex: 0, "aria-label": helpCenterlink, onClick: openHelpCentreLink, children: _jsx("i", { className: "mdi mdi-help-circle" }) }) }));
};
var NavigationMenu = function (_a) {
    var institution = _a.institution, user = _a.user;
    var _b = useContext(AppContext), institutionsList = _b.institutionsList, selectedInstitution = _b.selectedInstitution, setSelectedInstitution = _b.setSelectedInstitution;
    var selectInstitution = useIntl().formatMessage({
        id: "app.header.selectInstitution"
    });
    return (_jsx("div", { className: "menus", children: _jsxs("ul", { className: "menu-bar nobullets", children: [_jsx("li", { id: "instituition-list", className: "topbar-", tabIndex: -1, children: _jsxs("div", { id: "hierarchy-select", className: "prevent-header instituition-select", children: [(institutionsList === null || institutionsList === void 0 ? void 0 : institutionsList.length) === 1 && institutionsList[0].name, (institutionsList === null || institutionsList === void 0 ? void 0 : institutionsList.length) > 1 && (_jsx(WmSelect, { label: selectInstitution, labelPosition: "none", id: "select-institution", children: institutionsList.map(function (userInstitution) {
                                    return (_jsx(WmOption, { selected: selectedInstitution
                                            ? selectedInstitution === userInstitution.uuid
                                            : institution.platformUuid === userInstitution.uuid, onClick: function () {
                                            setSelectedInstitution(userInstitution);
                                        }, children: userInstitution.name }, "institution-".concat(userInstitution.uuid)));
                                }) }))] }) }), _jsx(HelpCenter, {}), _jsx(Navigator, { institution: institution, user: user })] }) }));
};
var Navigator = function (_a) {
    var institution = _a.institution, user = _a.user;
    return (_jsx("li", { className: "products dropdown-select", id: "naviagtor-icon", children: user && (_jsx(WmNavigator, { id: "navigator-example", "user-name": "".concat(user.firstName, " ").concat(user.lastName), email: user.email, "logout-url": user.logoutUrl, "auth-type": institution.authCode, products: user.products, mode: "insights_hub" })) }));
};
