var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useReducer } from "react";
import "../../app/App.scss";
import Header from "../../features/common/elements/Header";
import { applyPolyfills, defineCustomElements } from "@watermarkinsights/ripple/dist/loader";
import { sendRequest } from "../../common/http";
import { useParams } from "react-router-dom";
import OrganizationContent from "./OrganizationContent";
import { defaultOrganization } from "../common/interfaces/Interface";
import PssSideNav from "../common/elements/PssSideNav";
import { AppContext } from "src/contexts/AppContext";
import ScrollableHeader from "../common/elements/ScrollableHeader";
import UnauthorizedComponent from "src/features/insights/UnauthorizedComponent";
import Loader from "../common/elements/Loader";
import ApiError from "../common/elements/ApiError";
var defaultAction = {
    type: "",
    organization: defaultOrganization,
    component: ""
};
var reducer = function (state, action) {
    switch (action.type) {
        case "setOrganization":
            return __assign(__assign({}, state), { organization: action.organization, showMainLoader: false });
        case "setPssOrganization":
            return __assign(__assign({}, state), { pssOrganization: action.organization, showMainLoader: false });
        case "setApiError":
            return __assign(__assign({}, state), { apiError: true, showMainLoader: false });
        default:
            return state;
    }
};
var initialState = {
    organization: defaultOrganization,
    pssOrganization: defaultOrganization,
    showMainLoader: true,
    apiError: false
};
export function OrgInsightsBody(_a) {
    var auth = _a.auth;
    return _jsxs(_Fragment, { children: [" ", auth ? _jsx(OrgInsights, {}) : _jsx(UnauthorizedComponent, {}), " "] });
}
function OrgInsights() {
    var _a = useContext(AppContext), institution = _a.institution, sysUser = _a.sysUser;
    applyPolyfills().then(function () {
        defineCustomElements();
    });
    var _b = useParams(), institutionUuid = _b.institution_uuid, orgUuid = _b.organization_uuid;
    var _c = useReducer(reducer, initialState), state = _c[0], dispatch = _c[1];
    useEffect(function () {
        if (institutionUuid && orgUuid && institution) {
            var onError = function () {
                dispatch(__assign(__assign({}, defaultAction), { type: "setApiError" }));
            };
            if (institution.navigatedFrom === "navigator") {
                var applyResult = function (result) {
                    dispatch(__assign(__assign({}, defaultAction), { type: "setOrganization", organization: result }));
                };
                sendRequest({
                    uri: "/org_insights/institution/".concat(institutionUuid, "/organization/").concat(orgUuid, "/organization_info"),
                    camelize: true
                }, applyResult, onError);
            }
            else if (institution.navigatedFrom === "planning") {
                var applyResult = function (result) {
                    dispatch(__assign(__assign({}, defaultAction), { type: "setPssOrganization", organization: result }));
                };
                sendRequest({
                    uri: "/org_insights/pss/organization/".concat(orgUuid),
                    camelize: true
                }, applyResult, onError);
            }
        }
    }, [institutionUuid, orgUuid]);
    var platformUuid = (state.organization && state.organization.uuid) ||
        state.pssOrganization.platformUuid;
    return (_jsxs(_Fragment, { children: [state.showMainLoader && _jsx(Loader, {}), state.apiError ? (_jsx(ApiError, {})) : (_jsxs(_Fragment, { children: [institution && institution.navigatedFrom === "navigator" && (_jsx(NavigatorOrgInsightsView, { institution: institution, user: sysUser, organization: state.organization, platformUuid: platformUuid })), institution && institution.navigatedFrom === "planning" && (_jsx(PlanningOrgInsightsView, { institution: institution, organization: state.organization, pssOrganization: state.pssOrganization, platformUuid: platformUuid }))] }))] }));
}
var PlanningOrgInsightsView = function (_a) {
    var institution = _a.institution, organization = _a.organization, pssOrganization = _a.pssOrganization, platformUuid = _a.platformUuid;
    var displayName = (organization && organization.name) ||
        pssOrganization.name ||
        (institution && institution.name);
    return (_jsx("div", { children: institution && institution.platformUuid != "" && (_jsx(_Fragment, { children: _jsxs("main", { id: "maincontent", className: "main-body-container org-programs-wrapper", children: [_jsx(Header, { displayName: displayName }), _jsx(ScrollableHeader, { displayName: displayName }), _jsxs("div", { className: "insights-hub-body flex-row", children: [_jsx(PssSideNav, { organization: organization, pssOrganization: pssOrganization }), _jsx("div", { id: "main-org-profile-container", className: "main-org-profile", children: _jsx(OrganizationContent, { platformUuid: platformUuid }) })] })] }) })) }));
};
var NavigatorOrgInsightsView = function (_a) {
    var institution = _a.institution, user = _a.user, organization = _a.organization, platformUuid = _a.platformUuid;
    var displayName = (organization && organization.name) || (institution && institution.name);
    return (_jsx("div", { children: institution && user && institution.platformUuid != "" && (_jsx(_Fragment, { children: _jsxs("main", { id: "maincontent", className: "main-body-container-home", children: [organization.platformUuid !== "" && (_jsx(Header, { displayName: displayName })), _jsx(ScrollableHeader, { displayName: displayName }), _jsx("div", { className: "insights-hub-body flex-row", children: _jsx("div", { id: "main-org-profile-container", className: "main-org-profile", children: _jsx(OrganizationContent, { platformUuid: platformUuid }) }) })] }) })) }));
};
